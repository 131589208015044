import News from '@/models/News';

export default class NewsRepository {
    public static getAll() {
        return News.all();
    }

    public static getAllArchived() {
        return News.getAllArchived();
    }

    public static getById(newsId: string) {
        return News.query()
            .where(newsId)
            .withAll()
            .first();
    }
}
