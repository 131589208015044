
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import { UploadFile } from 'ant-design-vue/types/upload';
import Attachment from '@/models/Attachment';
import { convertFileToAntdFile } from '@/helpers/CmsIndexHelper';
import Image from '@/models/Image';
// @ts-ignore
import downloadFile from 'downloadjs';
import { Upload } from 'ant-design-vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import NewsAttachmentRepository from '@/repositories/NewsAttachmentRepository';
import NewsAttachment from '@/models/NewsAttachment';
import { CustomRequestParameters } from '@/interfaces/general/CustomRequestParameters';
import { customHandlerForFileUpload } from '@/helpers/NewsAttachment/NewsAttachmentHelper';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'NewsAttachment',
    components: {
        UploadDragger: Upload.Dragger,
        SidebarCard,
    },
})
export default class CmsNewsAttachment extends Vue {
    @Prop({ required: false, default: null }) private newsId!: string;
    @Prop({ required: false, default: false }) private isReadOnly!: boolean;
    private fetchedNewsId: string | null = null;
    private loading: string | null = null;
    private showDropzone = false;
    // private attachments: NewsAttachment[] = [];
    private temporaryAttachmentsForUpload: File[] | null = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    // hidding of the dropzone is delayed, to prevent flickering effect when draging over different child components
    private toggleDropzineTimer: NodeJS.Timeout | undefined;
    private attachments: any = [];

    // private get attachments(): NewsAttachment[] {
    //     return NewsAttachmentRepository.getByNewsId(this.newsId);
    // }

    private async initializeNewsAttachment() {
        const response = await NewsAttachment.getNewsAttachmentByNewsId(this.newsId);
        this.attachments = response.data;
    }

    private addNewAttachment(file: File) {
        this.attachments.push(file);
    }

    // Add files manually which will be uploaded or canceled if not saved
    private uploadTemporarySavedFiles(id: string) {
        this.fetchedNewsId = id;

        this.attachments?.forEach(async (file: File) => {
            await this.manualUpload(file);
        });
    }

    private async manualUpload(file: any) {
        if ((!this.newsId && !this.fetchedNewsId) || !file.uid) {
            return;
        }

        await this.customRequest({
            file,
            onSuccess: (formData: FormData) => {
                return;
            },
            onError: () => {
                return;
            },
        });
        return false;
    }

    private async customRequest(customRequestParameters: CustomRequestParameters) {
        await customHandlerForFileUpload(customRequestParameters, String(this.newsId || this.fetchedNewsId));
        await this.initializeNewsAttachment();
    }

    private startLoading(newsAttachmentId: string) {
        this.loading = newsAttachmentId;
    }

    private endLoading() {
        this.loading = null;
    }

    private onOpenRemoveModal(newsAttachmentForDelete: NewsAttachment) {
        return new Promise((resolve) => {
            this.$confirm({
                title: this.$t('Jeste li sigurni da želite primjeniti ove promjene?') as string,
                content: '',
                okText: this.$t('Da') as string,
                okType: 'danger',
                cancelText: this.$t('Ne') as string,
                class: 'c-delete-modal',
                onOk: () => {
                    this.onRemove(newsAttachmentForDelete);
                    resolve(null);
                },
                onCancel: () => {
                    resolve(null);
                },
            });
        });
    }
    private async onRemove(newsAttachmentForDelete: NewsAttachment) {
        // Delete locally if it is not saved yet
        if (newsAttachmentForDelete.uid) {
            this.attachments = this.attachments.filter(
                (attachment: NewsAttachment) => attachment.uid !== newsAttachmentForDelete.uid
            );
        } else {
            this.startLoading(newsAttachmentForDelete.id);
            this.loadingOverlay.start();

            try {
                await NewsAttachment.deleteExisting(newsAttachmentForDelete.id);

                // Delete locally
                this.attachments = this.attachments.filter(
                    (attachment: NewsAttachment) => attachment.id !== newsAttachmentForDelete.id
                );
            } catch (e) {
                this.$notification.error({
                    message: this.$t('Greška') as string,
                    description: this.$t('Došlo je do greške prilikom brisanja priloga') as string,
                });
            } finally {
                this.loadingOverlay.stop();
            }

            this.$notification.success({
                message: this.$t('Uspješno') as string,
                description: this.$t('Prilog je uspješno obrisan') as string,
            });
            this.endLoading();
        }
    }

    private async onPreview(newsAttachment: any) {
        if (newsAttachment.uid) {
            this.downloadLocalFile(newsAttachment);
        } else {
            let blob;
            this.startLoading(newsAttachment.id);
            try {
                blob = await NewsAttachment.getBlob(newsAttachment.attachment as string);
            } catch (e) {
                if (e instanceof Error) {
                    throw new Error(e.message);
                } else {
                    throw new Error(
                        (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message
                    );
                }
                return;
            }
            downloadFile(blob.data, newsAttachment.displayName);
            this.endLoading();
        }
    }

    private downloadLocalFile(attachment: File) {
        const reader = new FileReader();

        // download local file
        reader.onload = (e) => {
            const data = e?.target?.result;
            downloadFile(data, attachment.name);
        };
        reader.readAsDataURL(attachment);
    }

    private async created() {
        await this.initializeNewsAttachment();

        EventBus.$on(EventBusEvents.uploadNewsAttachment, this.uploadTemporarySavedFiles);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.uploadNewsAttachment);
    }
}
