
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import CmsNewsAttachment from '@/views/NewsAttachment.vue';
import RichTextEditor from '@/components/views/CmsIndex/RichTextEditor.vue';
import News from '@/models/News';
import NewsRepository from '@/repositories/NewsRepository';
import { NewsReport } from '@/interfaces/components/news/NewsReport';
import { NewsForm } from '@/interfaces/components/news/NewsForm';
import LocalePicker from '@/components/global/LocalePicker.vue';
import LocaleRepository from '@/repositories/LocaleRepository';

@Component({
    name: 'NewsAddNew',
    components: {
        CmsNewsAttachment,
        RichTextEditor,
        LocalePicker,
    },
})
export default class NewsAddNew extends Vue {
    @Prop({ required: false, default: null }) private id!: string;
    @Prop({ required: false, default: false }) private isReadOnly!: boolean;
    @Prop({ required: false, default: false }) private isAdmin!: boolean;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private selectedRowKeys: string[] = [];
    private isLoaded = false;

    // Main object for creating reports
    private formData: NewsForm = {
        title: '',
        content: '',
        locale: {
            code: this.currentLocale,
            id: '',
        },
    };

    private get isEditMode() {
        return !!this.id;
    }

    private get currentLocale() {
        if (this.$i18n == null) {
            return 'sl';
        }
        return this.$i18n.locale;
    }

    private get locales() {
        return LocaleRepository.getAll();
    }

    // Format data and send to BE
    private async createReport() {
        if (!this.isAdmin) {
            this.$notification.error({
                message: this.$t('Error updating news'),
                description: this.$t('You are not authorized to edit news'),
            });
            return;
        }

        if (!this.checkAreFieldsAreValid()) {
            return;
        }

        this.setLocaleIdForBE();
        this.loadingOverlay.start();

        try {
            // API call to the backend to create a new report and attachments in that order
            if (this.isEditMode) {
                await News.updateExisting(this.id, this.formData);
                EventBus.$emit(EventBusEvents.uploadNewsAttachment, this.id);
            } else {
                const newsData = await News.createNew(this.formData);
                EventBus.$emit(EventBusEvents.uploadNewsAttachment, newsData.data.id);
            }
        } catch (err) {
            this.$notification.error({
                message: this.$t('Error while ' + this.isEditMode ? 'updating' : 'creating' + 'news'),
                description: '',
            });
            return;
        } finally {
            this.loadingOverlay.stop();
        }

        this.$notification.success({
            message: this.$t('News ' + this.isEditMode ? 'updated' : 'created' + ' successfully'),
            description: '',
        });

        // Proceed to the reports list
        this.$emit('closePopup');
    }

    private checkAreFieldsAreValid() {
        if (!this.formData.title) {
            this.$notification.error({
                message: this.$t('Please add a title!'),
                description: '',
            });
            return false;
        }

        if (!this.formData.content) {
            this.$notification.error({
                message: this.$t('Please add a content!'),
                description: '',
            });
            return false;
        }

        return true;
    }

    private setLocaleIdForBE() {
        const locale = this.locales.find(
            (localeResource) => localeResource.code === this.formData.locale.code,
        );
        if (locale) {
            this.formData.locale.id = locale.id;
        }
    }

    private async mounted() {
        if (!this.isEditMode) {
            return;
        }

        this.loadingOverlay.start();
        this.isLoaded = false;

        let news;

        try {
            news = await News.getById(this.id);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error while loading news'),
                description: '',
            });
        } finally {
            this.loadingOverlay.stop();
            this.isLoaded = true;
        }

        if (news) {
            this.formData = {
                title: news.data.title,
                content: news.data.content,
                locale: {
                    code: news.data?.locale?.code ?? this.currentLocale,
                    id: news.data?.locale?.id ?? '',
                },
            };
        }
    }

    private created() {
        EventBus.$on(EventBusEvents.createNewsReport, this.createReport);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.createNewsReport, this.createReport);
    }
}
